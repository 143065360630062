* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-family: "poppins", "roboto", Arial, Helvetica, sans-serif;
  font-size: 62.5%;
  box-sizing: border-box;
  overflow-x: hidden;
  cursor: default;
}
.sticky-whatsapp {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  transition: all 0.2s linear;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  padding: 0.5rem;
  background: #00853e;
  cursor: pointer;
}
.sticky-whatsapp-icon {
  color: white;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
}
.sticky-whatsapp:hover {
  background: white;
}
.sticky-whatsapp:hover .sticky-whatsapp-icon {
  color: #00853e;
  transform: scale(0.9);
}
.cursor {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;

  border-radius: 200px;
  border: 1px solid #00853e;
  pointer-events: none;
}
.app {
  min-height: 100vh;
  /* height: max-content; */
  /* width: 100vw; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.459) 0%,
    #000000be 50%
  );
  width: 100%;
  height: 100%;
}

/* .overlay.active{
 
  z-index: 10;
  background: #000;
} */
.video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  z-index: -1;
}
.video video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.logo {
  position: absolute;
  top: 2rem;
  left: 3vw;
  z-index: 100;
  max-width: 20rem;
}
.logo img {
  width: 100%;
  height: 100%;
}

.content {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 100rem;
  margin: auto;
  text-align: center;
  font-family: "Poppins";
  color: white;
  text-transform: capitalize;
}
.content .row2 {
  display: flex;

  width: 100%;
  justify-content: center;
  gap: 2rem;
}
.content .row1 {
  display: flex;

  justify-content: center;
}
p.launch {
  padding-top: 3rem;
}
p {
  font-size: 3rem;
  text-transform: uppercase;
}

h2 {
  font-size: 4rem;
  font-weight: 600;
}

.img {
  width: 30rem;
}
img {
  width: 100%;
  height: 100%;
}
.logo-heading {
  height: 15rem;
}

/* buttons */

.btn {
  margin-top: 1rem;
  min-width: 15.5rem;
  border: none;
  outline: none;

  padding: 1.5rem;
  background: #00853ebf;
  font-size: 1.4rem;
  line-height: 14px;
  color: white;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.2s ease;
  letter-spacing: 1px;
  font-family: "Roboto";
  text-transform: uppercase;
  position: relative;
}
.btn:hover {
  color: #2f9f72d3;
  background: white;
  padding-right: 2.5rem;
}
.btn .chevron {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-55%);
  visibility: hidden;
  opacity: 0;
}
.btn:hover .chevron {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1400px) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 750px) {
  .logo {
    max-width: 15rem;
  }
  .logo-heading {
    height: 10rem;
  }
  .img {
    width: 25rem;
  }
  p {
    font-size: 2rem;
  }
  h2 {
    font-size: 3rem;
  }
  /* .btn {
    margin-top: 1rem;
  } */
}

@media (max-width: 500px) {
  .logo-heading {
    height: 8rem;
  }
  .img {
    width: 20rem;
  }
  .row2 {
    flex-wrap: wrap;
  }
}
