.backdrop {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

.modal {
  position: fixed;
  top: 5rem;
  width: 50rem;
  left: calc(50% - 25rem);
  background: white;

  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 200;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-header img {
  width: 20rem;
}
.closeModal {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  color: grey;
}

.closeModal:hover {
  color: black;
}

.form {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
input,
textarea {
  padding: 1rem;
  border: none;
  border-bottom: 1px solid rgb(71, 70, 70);
  outline: none;
  color: rgb(49, 49, 49);
  width: 100%;
  font-size: 1.4rem;
  font-family: "roboto";
}
input::placeholder,
textarea::placeholder {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-family: "roboto";
}
.errorMessage {
  color: red;
  font-size: 1.3rem;
  text-transform: capitalize;
}

.toast-font-size {
  font-size: 1.4rem;
  width: max-content;
  color: green;
  text-transform: capitalize;
}

.form .btn:hover {
  font-weight: bold;
  box-shadow: rgba(22, 168, 66, 0.25) 0px 50px 100px -20px,
    rgba(38, 141, 55, 0.3) 0px 30px 60px -30px,
    rgba(29, 168, 103, 0.35) 0px -2px 6px 0px inset;
}
@media (max-width: 500px) {
  .modal {
    width: 30rem;
    left: calc(50% - 15rem);
  }
  .modal-header img {
    width: 12rem;
  }
}
